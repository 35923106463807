<template>
    <form @submit.prevent="submit" class="login" @change="validate($event.target.name)">
        <div class="d-flex-r-jsb-ac">
            <a :href="$href({name : 'Register'})" @click.prevent="$push({name : 'Register'})">ثبت نام</a>
            <a class="selected">ورود</a>
        </div>

        <the-input v-model="data.email" type="text" :align="data.email? 'left' : 'right'"
                   :invalid-text="state.error.email" placeholder="ایمیل" name="email"/>
        <the-input v-model="data.password" type="password" :align="data.password? 'left' : 'right'"
                   :invalid-text="state.error.password" placeholder="گذرواژه" name="password">
            <template #info v-if="state.error.password || !data.password">
                <a class="info" :href="$href({name : 'ّForgetPass'})" style="padding: 0 10px"
                   @click.prevent="$push({name : 'ForgetPass'})">گذرواژه مرا بازیابی کن</a>
            </template>
        </the-input>

        <vue-recaptcha class="d-flex-r-ac-g1-s1" sitekey="6LezA0gcAAAAAGAcICq898569tI4DMHOfiYos_oo" :key="theme"
                       :size="theme"
                       theme="light" language="fa"
                       :loadRecaptchaScript="true" @verify="verify(recaptcha)" @expired="recaptcha=false"
                       @error="recaptcha=false"/>
        <the-button flat class="gradient-template-linear-pink" type="submit" :title="Object.values(state.error)" @click="submit">
            ورود
        </the-button>
        <the-button border class="gradient-template-google" type="button">
            ورود / ثبت نام با گوگل
            <inline-svg :src="require('@/assets/Icons/Google-Logo.svg')" style="margin-right: 15px"/>
        </the-button>
    </form>
</template>

<script>
    import TheInput from "@/components/Tools/TheInput";
    import VueRecaptcha from 'vue-recaptcha';
    import TheButton from "@/components/Tools/TheButton";
    import {verify} from "@/lib/reuseableFuncrtions"

    let errorTemplate = {
        email: {
            required: true,
            regexName: 'email'
        },
        password: {
            required: true,
        },
    }

    export default {
        name: "Login",
        components: {TheButton, TheInput, VueRecaptcha},
        data() {
            return {
                data: {
                    email: '',
                    password: '',
                },
                recaptcha: false,
                theme: 'normal'
            }
        },
        methods: {
            async submit() {
                if (await this.$validate(this.data, errorTemplate) && this.recaptcha) {

                    this.$cookies.set('username', this.data.email)
                    this.state.userInfo.email = this.data.email
                    // this.state.userInfo.mobilenumber = res.baseDTO.starTwoStepAuthentication || '';
                    this.state.userInfo.mobilenumber = '0912*****12';

                    this.$push({name: 'OTP', params: {pathMatch: 'SMS'}})
                }
            },
            validate(key) {
                let data = {}
                data[key] = this.data[key]
                this.$validate(data, errorTemplate)
            },
            verify
        },
        beforeDestroy() {
            console.log(document.body.lastChild.remove())
        }
    }
</script>

<style lang="scss" scoped>
    .mini-link {
        font-size: 12px;
        align-self: flex-start;
        margin-top: -19px;
        text-decoration: underline;
        text-decoration-thickness: 1px;
        text-decoration-color: var(--blue1);
        text-underline-offset: 5px;

        &.text-gradient {
            --color1: var(--blue1);
            --color2: var(--blue2);
        }
    }
</style>